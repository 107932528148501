.sidebar {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(112, 112, 112, 0);
  background-color: #f5f5f5;
  height: 100%;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.sidebar a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75px;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 14px;
  text-align: center;
  text-decoration: none;
  color: #999999;
  margin-bottom: 4em;
}
.sidebar > a:last-child {
  margin-bottom: 0;
}
.sidebar a img {
  margin-bottom: 0.5em;
}
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  position: fixed;
  top: 0;
  left: 100px;
  right: 0;
  z-index: 99;
}
.Header a {
  display: inline-block;
  text-decoration: none;
}
.Header a p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.topGradient {
  height: 102px;
  transform: rotate(-180deg);
  background-image: linear-gradient(
    180deg,
    rgba(241, 241, 241, 0) 0%,
    rgba(241, 241, 241, 0.78) 45%,
    #f1f1f1 100%
  );
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
}
.bottomGradient {
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 0;
  height: 88px;
  background-image: linear-gradient(
    180deg,
    rgba(241, 241, 241, 0) 0%,
    rgba(241, 241, 241, 0.78) 45%,
    #f1f1f1 100%
  );
}
