.CreateSection h1 {
  color: #dfe8eb;
  font-size: 130px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
.CreateSection h4 {
  font-size: 24px;
  font-weight: 700;
  color: #dfe8eb;
  text-transform: uppercase;
  margin: 0;
}
.CreateSection {
  height: 100%;
  position: relative;
  margin: 1em 2em 0 2em;
}
.CreateSection .footer {
  position: fixed;
  bottom: 0;
  left: 100px;
  right: 0;
  height: 88px;
}
.CreateCard {
  width: 148px;
  height: 173px;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  background-color: #2160bf;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 3em;
  cursor: pointer;
}
.CreateCard span {
  font-size: 15px;
  font-weight: 700;
  color: #fbfbfb;
  align-self: flex-end;
  text-align: end;
}
.projectsSection {
  width: 100%;
  max-width: 1300px;
  margin-left: -16px;
  display: flex;
  flex-wrap: wrap;
}
.projectsSection > div:nth-child(2) {
  background-color: #64b5f5;
}
.projectsSection > div:nth-child(3) {
  background-color: #feab91;
}
.projectsSection > div:nth-child(4) {
  background-color: #64b5f5;
}
.projectsSection > div:nth-child(5) {
  background-color: #64b5f5;
}
.projectsSection > div:nth-child(6) {
  background-color: #fdb64d;
}
.projectsSection > div:nth-child(7) {
  background-color: #80c783;
}
.projectsSection > div:nth-child(8) {
  background-color: #cfd8dd;
}
