.PlaceHolderSection {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  height: 100%;
  margin: 0 2em;
}
.PlaceHolderSection h1 {
  color: #dfe8eb;
  font-size: 130px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  position: fixed;
  top: 0.6em;
  left: 1em;
}
.PlaceHolderSection h4 {
  font-size: 24px;
  font-weight: 700;
  color: #dfe8eb;
  text-transform: uppercase;
  margin: 0;
  position: fixed;
  top: 9em;
  left: 5.7em;
}
.tracksSection {
  width: 100%;
  max-width: 1006px;
  margin: auto;
  margin-top: 18em;
  margin-bottom: 12em;
  z-index: 9;
  /* margin-top: 4em; */
}
.musicalNote {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* background-color: #e7b2e9;
  background-color: white !important; */
}

.musicalNote .avatarPic {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.musicalNote .typeIcon {
  width: 30px;
}
.music {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
  cursor: pointer;
}

.activeItem {
  border: 3px solid black !important;
}
.music .content {
  border-radius: 10px;
  background-color: #916592;
  margin: 0 1em;
  padding: 0.5em 0 0.5em 1em;
  width: 100%;
  max-width: 808px;
  border: 3px solid transparent;
  /* position: relative; */
}
.music .content p {
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-left: 2em;
}
.music .track {
  width: 100%;
  max-width: 726px;
  margin-left: auto;
  display: block;
}
.music h6 {
  width: 100%;
  max-width: 726px;
  margin-left: auto;
  display: block;
  /* margin: 0; */
}
.music button {
  border: none;
  outline: none;
  box-shadow: 0;
  background-color: transparent;
  color: #fff;
}
.music button svg {
  font-size: 2em;
}
.music .content .timer {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 8px;
  font-weight: 400;
  color: #fff;
}
.tracksSection > .music:last-child > .musicalNote {
  /* border: none; */
  background-color: transparent;
}
.tracksSection > .music:last-child > .content {
  /* border: none; */
  background-color: transparent;
  /* height: 165px; */
}
