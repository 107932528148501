.AddNewSection h1 {
  color: #dfe8eb;
  font-size: 130px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.AddNewSection h4 {
  font-size: 24px;
  font-weight: 700;
  color: #dfe8eb;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  top: 156px;
  left: 0;
}
.AddNewSection {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  margin: 1em 2em 0 2em;
}
.tracksSection {
  width: 100%;
  max-width: 916px;
  margin: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 13px;
  border: 1px solid rgba(112, 112, 112, 0);
  background-color: #f5f5f5;
  padding: 1.5em;
}
.tracksSection h2 {
  font-size: 21px;
  font-weight: 700;
  color: #999999;
  text-transform: uppercase;
  margin: 0;
}
.tracksSection h6 {
  font-size: 15px;
  font-weight: 400;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
  margin-top: 6em;
}
.tracksSection h6 img {
  margin-right: 1em;
}
.cards {
  width: 100%;
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.card {
  border-radius: 10px;
  background-color: #a7feeb;
  text-align: center;
  padding: 1em 0.5em;
  width: 100%;
  max-width: 124px;
  margin-left: 1em;
  margin-bottom: 1em;
  cursor: pointer;
}

.card input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card p {
  font-size: 10px;
  font-weight: 400;
  color: #000000;
  text-transform: uppercase;
  margin: 0.5em auto;
  width: 100%;
  max-width: 68px;
}
.cards > .card:nth-child(2) {
  background-color: #feab91;
}
.cards > .card:nth-child(3) {
  background-color: #e7b2e9;
}
.cards > .card:nth-child(4) {
  background-color: #f5f06a;
}
.cards > .card:nth-child(5) {
  background-color: #f9a6a6;
}
.cards > .card:nth-child(6) {
  background-color: #cfd8dd;
}
.cards > .card:nth-child(7) {
  background-color: #64b5f5;
}

.addItemPopup {
  width: 600px;
}

.BuildProject .card {
  background-color: transparent !important;
}

.getStarted img {
  width: 194px;
  height: 194px;
}

.getStarted h3 {
  color: #4b4b4b;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
}

.getStarted h5 {
  color: #aeaeae;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
