.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: #f5f5f5; */
  z-index: 99;
}
.footer > div > div {
  margin: 0.5em 2em;
  text-align: center;
}
.footer > div {
  cursor: pointer;
}

.footer p {
  font-size: 10px;
  font-weight: 400;
  color: #636363;
  margin: 0;
  margin-top: 0.3em;
}
.customAvatar {
  position: absolute;
  right: 90px;
  bottom: 14px;
}
