.userImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
