.ProjectCard {
  width: 289px;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  background-color: #a7feeb;
  padding: 1em 1em 0.5em 1em;
  margin-left: 16px;
  margin-bottom: 16px;
}
.ProjectCard button {
  background-color: transparent;
  border: none;
  display: flex;
  font-size: 1.7em;
  padding: 0;
  margin-right: 1em;
}
.ProjectCard h5 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #000000;
}
.ProjectCard h6 {
  font-size: 8px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}
.ProjectCard p {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin: 0;
  line-height: 14px;
}
.ProjectCard img {
  width: 16px;
  margin-right: 1.5em;
  cursor: pointer;
}
.ProjectCard svg {
  cursor: pointer;
}
