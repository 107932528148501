body {
  background-color: #f9f9f9 !important;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: #000;
}
.App {
  display: flex;
  min-height: 100vh;
  background-color: #f1f1f1;
}

.layout-wrapper {
  min-height: calc(100vh - 100px);
  margin-top: 82px;
  margin-left: 100px;
  width: 100%;
}

#subtitles > .marks.active {
  background-color: yellow;
}

.highlighted {
  color: red;
  background-color: yellow;
}

::-moz-selection {
  /* Code for Firefox */
  color: red !important;
  background: yellow !important;
}

::selection {
  color: red !important;
  background: yellow !important;
}

#content {
  /* overflow-y: scroll;
  height: 200px;
  padding: 1em;
  border-radius: 10px;
  border: 1px solid gray; */
}

.word {
  /* margin-right: 5px; */
}

.ce-block {
  /* display: inline-block; */
}

.ce-block__content,
.ce-toolbar__content {
  /* max-width: none; */
}

/* Let's highlight canvas boundaries */
/* #editor {
  border: 3px solid #444;
} */

/* Reset some default styling */
/* .gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
} */

.editor-button {
  background-color: darkslategrey;
  color: white;
  padding: 8px !important;
  line-height: 1 !important;
}

.customPopup {
  background-color: #2f1e07;
  width: 200px;
  position: absolute;
  border-radius: 6px;
  z-index: 99;
}

.customPopup > .popupButton {
  display: block;
  color: white;
  padding: 0.2em 1em;
  transition: 200ms all ease-in-out;
  margin: 10px;
}

.customPopup > .popupButton:hover {
  background-color: slategray;
}

.spinner-parent {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Backgrounds-Tile {
  padding: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.addItems {
  border: 1px solid;
  border-radius: 50%;
  width: max-content;
  padding: 0px 4px 2px;
  position: absolute;
  background: black;
  color: white;
  font-weight: 600;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
}

.d-none {
  display: none;
}

.dropdown_cls {
  position: absolute;
  background: white;
  box-shadow: 0 0 4.14286px rgb(0 2 40 / 10%);
  width: max-content;
  padding: 0.5rem;
}

.dropdown-options {
  padding: 0.5rem;
}

.dropdown-options:hover {
  background: #f3f4f6;
}

/* medium style */

.medium-editor-element {
  outline: none;
}

.medium-toolbar-arrow-under:after {
  border-color: #2f1e07 transparent transparent;
  top: 60px;
}

.medium-toolbar-arrow-over:before {
  border-color: transparent transparent #2f1e07;
}

.medium-editor-toolbar {
  background-color: #2f1e07;
  border: 1px solid #5b3a0e;
  border-radius: 6px;
}

.medium-editor-toolbar li button {
  background-color: transparent;
  border: none;
  border-right: 1px solid #5b3a0e;
  box-sizing: border-box;
  color: #ffedd5;
  height: 60px;
  min-width: 60px;
  -webkit-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.medium-editor-toolbar li .medium-editor-button-active,
.medium-editor-toolbar li button:hover {
  background-color: #030200;
  color: #ffedd5;
}

.medium-editor-toolbar li .medium-editor-button-first {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.medium-editor-toolbar li .medium-editor-button-last {
  border-bottom-right-radius: 6px;
  border-right: none;
  border-top-right-radius: 6px;
}

.medium-editor-toolbar-form {
  background: #2f1e07;
  border-radius: 6px;
  color: #ffedd5;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input {
  background: #2f1e07;
  color: #ffedd5;
  height: 60px;
}

.medium-editor-toolbar-form
  .medium-editor-toolbar-input::-webkit-input-placeholder {
  color: #ffedd5;
  color: rgba(255, 237, 213, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:-moz-placeholder {
  color: #ffedd5;
  color: rgba(255, 237, 213, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input::-moz-placeholder {
  color: #ffedd5;
  color: rgba(255, 237, 213, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:-ms-input-placeholder {
  color: #ffedd5;
  color: rgba(255, 237, 213, 0.8);
}

.medium-editor-toolbar-form a {
  color: #ffedd5;
}

.medium-editor-toolbar-anchor-preview {
  background: #2f1e07;
  border-radius: 6px;
  color: #ffedd5;
}

.medium-editor-placeholder:after {
  color: #5b3a0e;
}

.ant-tag {
  cursor: pointer;
}

.ant-modal-header,
.ant-modal-footer {
  display: none;
}

.addItemPopup {
  width: 1000px !important;
}

.addItemPopup .ant-modal-content {
  width: 1000px;
  box-shadow: none;
  background: transparent;
}

.addItemPopup .ant-modal-close {
  display: none;
}
.buildProjectPopup {
  width: 1100px !important;
}

.buildProjectPopup .ant-modal-content {
  width: 1100px;
}

.ant-modal {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.medium-editor-element {
  min-height: 70px !important;
}

iframe {
  display: none;
}
