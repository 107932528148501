.sideMenu {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
  border-radius: 0.5em;
  padding: 1em 1.5em;
  position: fixed;
  right: 1.5em;
  top: 6.5em;
  width: 343px;
  height: 80vh;
  overflow: hidden;
}
.sideMenu h2 {
  font-size: 18px;
  font-weight: 700;
  color: #636363;
  text-transform: uppercase;
  text-align: end;
  margin-bottom: 1.5em;
}
.invite {
  display: flex;
  margin-bottom: 1em;
}
.invite input {
  border-radius: 5px;
  border: 1px solid #000000;
  color: #5d5d5d;
  font-size: 12px;
  font-weight: 400;
  padding: 0.5em 1em;
  margin-right: 1em;
  width: 100%;
}
.invite button {
  border-radius: 5px;
  background-color: #2160bf;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  padding: 0.5em;
  border: none;
}
.actionCards {
  margin-bottom: 3em;
}
.actionCards > div {
  width: 100%;
  max-width: 64px;
  border-radius: 5px;
  border: 1px solid transparent;
  text-align: center;
  padding: 0.45em 0.1em;
  margin: 0 0.5em;
  cursor: pointer;
}
.actionCards > div.active {
  border-color: #006cbe;
}
.actionCards p {
  font-size: 8px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin: auto;
}
.userInvitation h6 {
  font-size: 12px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}
.userInvitation p {
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  margin: 0;
}
.userInvitation button {
  border-radius: 5px;
  background-color: #5abf21;
  font-size: 11px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  border: none;

  padding: 0.4em 1em;
}
.userInvitation .primaryBtn {
  background-color: #2160bf;
}

.userItem {
  cursor: pointer;
}
